export function calcNPPA(gppa, prodCostPerUnit) {
	try {
		var result = (gppa - prodCostPerUnit).toFixed(2);

		if (isNaN(result)) {
			return "N/A";
		} else {
			return Number(result);
		}
	} catch (error) {
		return "ERR";
	}
}
