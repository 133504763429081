import React from "react";
import { useCSVDownloader } from "react-papaparse";
import { FaFileDownload } from "react-icons/fa";
/**
 *
 * @param {array} collection
 * @returns download csv file with entire office collection
 */
function DownloadOfficeCollection({ collection }) {
	const { CSVDownloader, Type } = useCSVDownloader();

	const convertFields = () => {
		let officeFields = collection.map((field) => ({
			UPC: field.upc,
			ASIN: field.asin,
			Notes: field.notes,
			Supplier: field.supplier,
			"Purchased Date": field.date,
			Quantity: field.qty,
			Title: field.amzProductTitle,
			Initials: field.initials,
			Attributes: field.attributes,
			"Unit Price": field.unitPrice,
			"Unit Cost": field.unitCost,
			"Sales Tax": field.salesTaxPaid,
			"Min Price": field.min,
			"Max Price": field.max,
			"Amazon Link": field.amzLink,
			Warehouse: field.warehouse,
			List: field.list,
			MSKU: field.msku,
		}));
		return officeFields;
	};

	var config = {
		delimeter: ",",
		header: true,
		skipEmptyLines: true,
	};

	const today = new Date();
	const date =
		today.getFullYear() + "_" + (today.getMonth() + 1) + "_" + today.getDate();

	return (
		<>
			<CSVDownloader
				className="col-2 w-auto mx-1 btn btn-turtle_4"
				type={Type.Button}
				filename={"officeCollection" + date}
				bom={true}
				config={config}
				data={convertFields}>
				<FaFileDownload />
			</CSVDownloader>
		</>
	);
}

export default DownloadOfficeCollection;
