import React, { useState, useContext } from "react";
import { Form, Dropdown, Button } from "react-bootstrap";
import UserContext from "../../context/UserContext";
import { useCSVDownloader } from "react-papaparse";

function QuarterlyReport() {
	const { CSVDownloader, Type } = useCSVDownloader();
	const { user } = useContext(UserContext);
	const [formatted, setFormatted] = useState([]);
	const [queryData, setQueryData] = useState({
		quarter: "",
		year: "",
	});

	const getQuarterlyData = async (e) => {
		e.preventDefault();
		try {
			const { quarter, year } = queryData;
			const allData = await user.functions.quarterlyTaxQuery(quarter, year);
			convertPlanFields(allData);
		} catch (err) {
			console.log(err);
		}
	};

	const yearsDescending = () => {
		const currentYear = new Date().getFullYear();
		const years = [];
		for (let i = currentYear; i >= 2019; i--) {
			years.push(i);
		}
		return years;
	};

	const convertPlanFields = (results) => {
		var formattedResults = results.map((field) => ({
			Date: field.date,
			"Product Title": field.amzProductTitle,
			Supplier: field.supplier,
			ASIN: field.asin,
			Min: field.min,
			Max: field.max,
			Quantity: field.qty,
			"Unit Cost": field.unitCost,
			"Sales Tax Paid": field.salesTaxPaid,
			Location: field.warehouse,
			"Order#": field.orderNumber,
			MSKU: field.msku,
			Notes: field.notes,
		}));
		setFormatted(formattedResults);
	};

	var config = {
		delimeter: ",",
		header: true,
		skipEmptyLines: true,
	};

	// grabs the keys from each object to set the headers
	const headerKeys = Object.keys(Object.assign({}, ...formatted));

	return (
		<div className="text-align-center">
			<Form
				className="d-flex justify-content-center mx-auto my-5"
				onSubmit={getQuarterlyData}>
				<Form.Group className="d-flex">
					<Dropdown className="mx-2">
						<Dropdown.Toggle variant="success" id="dropdown-basic">
							{queryData.quarter === "" ? "Quarter" : queryData.quarter}
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item
								onClick={() => setQueryData({ ...queryData, quarter: "Q1" })}>
								Q1
							</Dropdown.Item>
							<Dropdown.Item
								onClick={() => setQueryData({ ...queryData, quarter: "Q2" })}>
								Q2
							</Dropdown.Item>
							<Dropdown.Item
								onClick={() => setQueryData({ ...queryData, quarter: "Q3" })}>
								Q3
							</Dropdown.Item>
							<Dropdown.Item
								onClick={() => setQueryData({ ...queryData, quarter: "Q4" })}>
								Q4
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
					<Dropdown className="mx-2">
						<Dropdown.Toggle variant="success" id="dropdown-basic">
							{queryData.year === "" ? "Year" : queryData.year}
						</Dropdown.Toggle>

						<Dropdown.Menu>
							{yearsDescending().map((year) => (
								<Dropdown.Item
									onClick={() => setQueryData({ ...queryData, year: year })}>
									{year}
								</Dropdown.Item>
							))}
						</Dropdown.Menu>
					</Dropdown>
					<Button variant="success" type="submit">
						Submit
					</Button>
				</Form.Group>
			</Form>
			<CSVDownloader
				className="btn btn-turtle_4"
				type={Type.Button}
				filename={`QuarterlySalesTax_${queryData.quarter}_${queryData.year}`}
				bom={true}
				config={config}
				data={formatted}>
				Download CSV
			</CSVDownloader>
			<div className="overflow-scroll mt-3">
				<table className="table">
					<thead>
						<tr key={"header"}>
							{headerKeys.map((key) => (
								<th scope="col">{key}</th>
							))}
						</tr>
					</thead>
					<tbody>
						{formatted.map((item) => (
							<tr key={item.id}>
								{Object.values(item).map((val) => (
									<td>{val}</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default QuarterlyReport;
