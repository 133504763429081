import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import SubmitTimeoutModal from "./SubmitTimeoutModal";
import { UserContext } from "../context/UserContext";

const IdleTimeOutHandler = (props) => {
	const [showModal, setShowModal] = useState(false);
	const [isLogout, setIsLogout] = useState(false);
	const { logOutUser } = useContext(UserContext);

	let timer = undefined;
	const events = ["click", "scroll", "load", "keydown"];
	const eventHandler = (eventType) => {
		if (!isLogout) {
			localStorage.setItem("lastInteractionTime", moment());
			if (timer) {
				props.onActive();
				startTimer();
			}
		}
	};

	useEffect(() => {
		addEvents();

		return () => {
			removeEvents();
			clearTimeout(timer);
		};
	}, []);

	const startTimer = () => {
		if (timer) {
			clearTimeout(timer);
		}

		timer = setTimeout(
			() => {
				let lastInteractionTime = localStorage.getItem("lastInteractionTime");
				const diff = moment.duration(
					moment().diff(moment(lastInteractionTime))
				);

				let submitChangesInterval = 30000;
				let timeOutInterval = props.timeOutInterval
					? props.timeOutInterval
					: 28800000;

				if (isLogout) {
					console.log("isLogout");
					clearTimeout(timer);
				} else {
					// if (
					// 	diff.asMilliseconds() > submitChangesInterval &&
					// 	diff.asMilliseconds() < timeOutInterval &&
					// 	updates.length > 0
					// ) {
					// 	setShowModal(true);
					// }
					if (diff.asMilliseconds() < timeOutInterval) {
						startTimer();
						props.onActive();
					} else {
						props.onIdle();
						logOutUser();
					}
				}
			},
			props.timeOutInterval ? props.timeOutInterval : 60000
		);
	};

	const addEvents = () => {
		events.forEach((eventName) => {
			window.addEventListener(eventName, eventHandler);
		});
		startTimer();
	};

	const removeEvents = () => {
		events.forEach((eventName) => {
			window.removeEventListener(eventName, eventHandler);
		});
	};

	const handleContinueSession = () => {
		setShowModal(false);
		setIsLogout(false);
	};

	// const handleLogout = () => {
	// 	removeEvents();
	// 	clearTimeout(timer);
	// 	setIsLogout(true);
	// 	logOutUser();
	// 	props.onLogout();
	// 	setShowModal(false);
	// };

	return (
		<div>
			<SubmitTimeoutModal
				setShowModal={setShowModal}
				showModal={showModal}
				handleContinue={handleContinueSession}
			/>
		</div>
	);
};

export default IdleTimeOutHandler;
