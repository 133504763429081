import React, { createContext, useState, useEffect } from 'react';
import { json } from 'react-router-dom';
import * as Realm from 'realm-web';
import { toast } from 'react-toastify';
export const UserContext = createContext();

/** TODO: Honestly this whole component needs work, when I have time, I have
 * to come back to it and make it better
 */

const app = new Realm.App({ id: 'connectedapp-nlbaf' });
// const app = new Realm.App({ id: 'rdsn-testing-scllj' });

export const UserContextProvider = ({ children }) => {
	const [user, setUser] = useState(null);
	// const [loggedIn, setLoggedIn] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);

	// persist user so they don't get logged out when a page refreshes.
	useEffect(() => {
		if (user === undefined || null) {
			console.log('user not logged in');
			// console.log('App user: ', app.currentUser.refreshCustomData());
		} else if (user) {
			if (user.customData.isAdmin === true) {
				setIsAdmin(true);
			} else {
				setIsAdmin(false);
			}
		}
	}, [user]);

	const fetchUser = async () => {
		if (!app.currentUser) return false;
		try {
			await app.currentUser.refreshCustomData();
			setUser(app.currentUser);
			return app.currentUser;
		} catch (error) {
			throw error;
		}
	};

	async function Login(username, password) {
		const creds = Realm.Credentials.emailPassword(username, password);
		try {
			const currentUser = await app.logIn(creds);
			if (currentUser.customData.isAdmin) {
				setIsAdmin(true);
			}
			setUser(currentUser);
			toast.success('Logged in successfully');
			return true;
		} catch (error) {
			toast.error('Invalid username or password');
			return json(
				{
					error: 'an error has occured',
					errMessage: error,
				},
				{
					status: 400,
				}
			);
		}
	}

	// Function to logout user from our Realm
	const logOutUser = async () => {
		if (!app.currentUser) return false;
		try {
			await app.currentUser.logOut();
			// Setting the user to null once loggedOut.
			setUser(null);
			setIsAdmin(false);
			return true;
		} catch (error) {
			throw error;
		}
	};

	const value = {
		user,
		isAdmin,
		Login,
		fetchUser,
		logOutUser,
		app,
	};

	// we can now create a login page to set the user
	return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserContext;
