import React, { useContext } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import LogOutBtn from "./LogOutBtn";
import LogInBtn from "./LoginBtn";
import InstructionsCard from "../pages/OfficeCollection/InstructionsCard";
import UserContext from "../context/UserContext";

export const NavbarComponent = () => {
	// TODO: Learn how to use Sass and Mixins to make these kind of variables
	const linkStyle = "text-decoration-none text-light pe-auto";
	const { isAdmin, user } = useContext(UserContext);

	return (
		<Navbar className="bg-turtle_light" variant="dark" expand="lg">
			<Navbar.Brand className="ms-2" href="#home">
				Chic Products LLC
			</Navbar.Brand>
			<Navbar.Toggle aria-controls="basic-navbar-nav" />
			<Navbar.Collapse id="basic-navbar-nav">
				<Nav>
					<Nav.Link
						className={linkStyle}
						href="https://www.shopchicproducts.com/"
						target="_blank"
						rel="noopener noreferrer">
						Home
					</Nav.Link>
					<Nav.Link>
						<Link to="/" className={linkStyle}>
							OfficeLookup
						</Link>
					</Nav.Link>
					<NavDropdown
						title="Import"
						className={isAdmin === false ? "d-none" : "d-block"}
						style={{ zIndex: "1030" }}
						id="collapsible-nav-dropdown">
						<NavDropdown.Item>
							<Link to="import" className="text-decoration-none text-dark">
								CSV
							</Link>
						</NavDropdown.Item>
						<NavDropdown.Item>
							<Link to="singleDoc" className="text-decoration-none text-dark">
								Single Doc
							</Link>
						</NavDropdown.Item>
						<NavDropdown.Item>
							<Link to="replen" className="text-decoration-none text-dark">
								Replen/Return
							</Link>
						</NavDropdown.Item>
					</NavDropdown>
					<NavDropdown
						title="Admin"
						className={isAdmin === false ? "d-none" : "d-block"}
						style={{ zIndex: "1030" }}
						id="collasible-nav-dropdown">
						<NavDropdown.Item>
							<Link to="masterView" className="text-decoration-none text-dark">
								Master
							</Link>
						</NavDropdown.Item>
						<NavDropdown.Item>
							<Link
								to="quarterlyReport"
								className="text-decoration-none text-dark">
								Sales Tax
							</Link>
						</NavDropdown.Item>
						<NavDropdown.Item>
							<Link to="metrics" className="text-decoration-none text-dark">
								Metrics Dashboard
							</Link>
						</NavDropdown.Item>
					</NavDropdown>
				</Nav>
				<InstructionsCard />
				{user === null ? <LogInBtn /> : <LogOutBtn />}
			</Navbar.Collapse>
		</Navbar>
	);
};
