import { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { UserContext } from "../context/UserContext";

const PrivateRoute = (props) => {
	//fetch user from context
	const { user } = useContext(UserContext);
	const location = useLocation();

	// redirect if user not logged in
	return user ? (
		<Outlet />
	) : (
		<Navigate to={`/login?redirectTo=${encodeURI(location.pathname)}`} />
	);
};
export default PrivateRoute;
