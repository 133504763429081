import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { UserContextProvider } from "./context/UserContext";
import { BrowserRouter } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
import "./scss/custom.scss";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { MongoContextProvider } from "./context/MongoContext";
import { MetricsContextProvider } from "./context/MetricsContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<BrowserRouter>
		<UserContextProvider>
			<MongoContextProvider>
				<MetricsContextProvider>
					<App />
				</MetricsContextProvider>
			</MongoContextProvider>
		</UserContextProvider>
	</BrowserRouter>
);
