import { Stack, Form, Button } from 'react-bootstrap';
import { useState, useContext } from 'react';
import TablePagination from '../../components/TablePagination';
import PaginationFooter from '../../components/PaginationFooter';
// import { MasterFields } from "../../mongo/fields/MasterFields";
import UserContext from '../../context/UserContext';
import { AdminProduct } from './AdminProductModel';
import MongoContext from '../../context/MongoContext';
import { Table } from 'reactstrap';

function AdminCatalog() {
	const [items, setItems] = useState([]);
	const [page, setPage] = useState(1);
	// add isEdit to this page, so we can edit all
	// values at once.
	const [isEdit, setIsEdit] = useState(false);

	// make search function another component altogether
	const [search, setSearch] = useState('');
	// const [searchBy, setSearchBy] = useState("amzProductTitle");
	const { user } = useContext(UserContext);
	const { pushToMaster } = useContext(MongoContext);
	const { slice, range } = TablePagination(items, page, 25);

	// get fields for dropdown
	// const master = Object.entries(MasterFields);

	/**
	 * @returns any data that matches MongoDb query from user
	 */
	//TODO: create a functions folder to separate functions like getData MAYBE
	const getData = async (e) => {
		e.preventDefault();
		try {
			const allData = await user.functions.queryMaster(search);
			console.log('Search successful!');
			console.log(allData);
			setItems(allData);
		} catch (err) {
			console.log(err);
		}
	};

	const handleValueChange = (e) => {
		e.preventDefault();
		setSearch(e.target.value);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		pushToMaster();
		setIsEdit(false);
	};

	//TODO: figure out how to show pagination only when there is items in the array
	return (
		<div className='container-fluid'>
			<Form className='my-3 text-dark' onSubmit={getData}>
				<Form.Group className='mb-3' controlId='formItemName'>
					<Form.Label>
						<strong>Search by Column Name</strong>
					</Form.Label>
					<Form.Control
						className='mx-auto w-50 text-center'
						type='text'
						onChange={handleValueChange}
					/>
					<div className='row mx-auto w-25'>
						<Button className='col-auto mx-auto mt-2' type='submit'>
							Search
						</Button>
						{/* <DropdownButton
							className="col-auto mx-auto mt-2"
							id="dropdown-basic-button"
							title={searchBy}>
							{master.map(([key, val], index) => {
								return (
									<Dropdown.Item key={index} onClick={() => setSearchBy(val)}>
										{key}
									</Dropdown.Item>
								);
							})}
						</DropdownButton> */}
					</div>
				</Form.Group>
			</Form>
			<div>
				{isEdit ? (
					<>
						<button
							className='btn btn-success mx-1'
							type='button'
							onClick={handleSubmit}>
							Submit
						</button>
						<button
							className='btn btn-danger mx-1'
							type='button'
							onClick={() => setIsEdit(false)}>
							Cancel
						</button>
					</>
				) : (
					<button
						className='btn btn-success'
						type='button'
						onClick={() => setIsEdit(true)}>
						Edit
					</button>
				)}
			</div>
			{items.length > 0 ? (
				<div>
					<h4>{items.length} entries found</h4>
					<Table responsive>
						<thead>
							<tr>
								<th>Date</th>
								<th>Attributes</th>
								<th>Min</th>
								<th>Max</th>
								<th>Amz Product Title</th>
								<th>Supplier</th>
								<th>Product Link</th>
								<th>Amz Link</th>
								<th>ASIN</th>
								<th>Qty</th>
								<th>Total Buy Price</th>
								<th>Unit Cost</th>
								<th>List</th>
								<th>Sales Tax</th>
								<th>Warehouse</th>
								<th>Order #</th>
								<th>MSKU</th>
								<th>UPC</th>
								<th>Units</th>
								<th>Units S/D/R</th>
								<th>Notes</th>
							</tr>
						</thead>
						<tbody>
							{slice.map((doc, index) => {
								return (
									<AdminProduct key={doc._id} props={doc} isEdit={isEdit} />
								);
							})}
						</tbody>
					</Table>
				</div>
			) : (
				<></>
			)}
			<PaginationFooter
				range={range}
				slice={slice}
				setPage={setPage}
				page={page}
			/>
		</div>
	);
}

export default AdminCatalog;
