import React, { useContext, useEffect } from "react";
import { MetricsContext } from "../../context/MetricsContext";
import MetricsRow from "./MetricsRow";

export default function MetricsDashboard() {
	const { state, getMetrics, updateMetric, saveMetricsToDb } =
		useContext(MetricsContext);
	const { metricsByMonth } = state;

	// we'll need a conditional to get the db metrics if they haven't been fetched.
	useEffect(() => {
		if (metricsByMonth.length === 0) {
			getMetrics();
		}
	}, []);

	const handleChange = (data) => {
		const metrics = data;

		updateMetric(metrics);
	};
	return (
		<>
			<div className="p-5 container-fluid">
				<h1 className="fst-italic fw-bold text-turtle_light">2024 Metrics</h1>
			</div>
			<div className="table-responsive">
				<table className="table table-striped table-bordered border-turtle_light m-1">
					<thead>
						<tr>
							<th scope="col">Mo.</th>
							{/* <th scope="col">Company Buys</th> */}
							<th scope="col">Mo. Units Shipped</th>
							<th scope="col">
								Buy <br />
								Spend
							</th>
							<th scope="col">
								Units <br />
								Sold
							</th>
							<th scope="col">COGS</th>
							<th scope="col">Revenue</th>
							<th scope="col">
								Gross <br /> Profit
							</th>
							<th scope="col">Expenses</th>
							<th scope="col">Dispmnts</th>
							<th scope="col">GPPA</th>
							<th scope="col">PCPA</th>
							<th scope="col">NPPA</th>
							<th scope="col">Margin</th>
						</tr>
					</thead>
					<tbody className="table-group-divider">
						{metricsByMonth.map((metric, index) => {
							return (
								<MetricsRow
									key={index}
									metric={metric}
									handleChange={handleChange}
								/>
							);
						})}
					</tbody>
				</table>
			</div>
			<div className="mt-5">
				<button
					className="btn btn-turtle_light rounded-pill"
					onClick={saveMetricsToDb}>
					Save Changes
				</button>
			</div>
		</>
	);
}
