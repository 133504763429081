import React, { useEffect } from 'react';
import { GrCaretPrevious, GrCaretNext } from 'react-icons/gr';

function PaginationFooter({ range, setPage, page, slice }) {
	const activeButton = 'btn btn-primary rounded-3 my-4 mx-2';
	const inactiveButton = 'btn btn-secondary rounded-3 my-4 mx-2';

	function goToNext() {
		setPage((page) => page + 1);
	}

	function goToPrevious() {
		setPage((page) => page - 1);
	}

	function getPaginationGroup() {
		let start = Math.floor((page - 1) / 5) * 5;
		let arrSize;
		// don't show more pages than are actually there.
		if (range <= 5) {
			arrSize = range;
		} else {
			arrSize = 5;
		}
		return new Array(arrSize).fill().map((_, idx) => start + idx + 1);
	}

	useEffect(() => {
		if (slice.length < 1 && page !== 1) {
			setPage(page - 1);
		}
	}, [slice, page, setPage]);

	if (range.length > 0) {
		return (
			<div className='d-flex justify-content-center text-align-start'>
				<button
					type={'button'}
					className={'btn rounded-start btn-primary mx-2 my-4'}
					disabled={page === 1 ? true : false}
					onClick={goToPrevious}>
					<GrCaretPrevious />
				</button>
				{getPaginationGroup().map((item, idx) => (
					<button
						className={page === item ? activeButton : inactiveButton}
						key={idx}
						onClick={() => setPage(item)}>
						{item}
					</button>
				))}
				<button
					type={'button'}
					className={'btn rounded-end btn-primary mx-2 my-4'}
					disabled={page === range.length ? true : false}
					onClick={goToNext}>
					<GrCaretNext />
				</button>
			</div>
		);
	}
}

export default PaginationFooter;
