function calculateTaxesPaid(percentage, cost, units) {
	var taxPercent;
	var costPreTax;
	// remove the dollar sign
	cost = cost.replace("$", "");

	// convert the cost to a float
	const unitCost = parseFloat(cost);
	let taxesPaid = 0;

	if (percentage !== "") {
		// remove the percent sign
		percentage = percentage.replace("%", "");
		// turn percentage into workable float
		taxPercent = parseFloat(percentage) * 0.01 + 1;
	} else {
		return taxesPaid;
	}

	// many products don't have a cost associated with them return 0 for those
	if (unitCost === undefined) {
		return taxesPaid;
	} else {
		costPreTax = unitCost / taxPercent;
		taxesPaid = (units * (unitCost - costPreTax)).toFixed(2);
		// taxes in texas amount to 8.20%
		// taxesPaid = (unitCost * 0.0825).toFixed(2);
		return taxesPaid;
	}
}
export default calculateTaxesPaid;
