import React, { useContext } from "react";
import { Button, UncontrolledTooltip } from "reactstrap";
import { AiOutlineClear } from "react-icons/ai";
import MongoContext from "../../context/MongoContext";

const ClearChangesBtn = () => {
	const { clearUpdates } = useContext(MongoContext);

	return (
		<Button
			id="clearChanges"
			className="col-2 w-auto mx-1  btn btn-turtle_4"
			onClick={clearUpdates}>
			<AiOutlineClear className="fs-5" />
			<UncontrolledTooltip placement="top" target="clearChanges">
				Clear Current Changes
			</UncontrolledTooltip>
		</Button>
	);
};

export default ClearChangesBtn;
