// access db operations from this service

// push updates to office collection
export const pushToOffice = async (user, updates) => {
	var confirmation = await user.functions.updateOfficeProducts(updates);
	return confirmation;
};

// push single document to office collection
export const pushSingleDocOffice = async (user, input) => {
	var confirmation = await user.functions.updateOfficeProducts(input);
	return confirmation;
};

// push staged collection to master collection
export const stagedToMaster = async (user) => {
	var confirmation = await user.functions.stagedToMaster();
	return confirmation;
};

// update master collection via admin catalog
export const updateMaster = async (user, updates) => {
	var confirmation = await user.functions.updateMaster(updates);
	return confirmation;
};

// get all items from office collection
export const getOfficeItems = async (user) => {
	var confirmation = await user.functions.getOfficeProducts();
	return confirmation;
};

export const getStagedProducts = async (user) => {
	var confirmation = await user.functions.getStagedData();
	return confirmation;
};

// insert multiple document into office collection
export const insertIntoOffice = async (user, input) => {
	var confirmation = await user.functions.insertIntoOffice(input);
	return confirmation;
};

// update the product information of a single document in the office collection
export const updateOfficeProductInfo = async (user, input) => {
	var confirmation = await user.functions.updateOfficeProductInfo(input);
	return confirmation;
};

// delete single document from office collection
export const deleteFromOffice = async (user, input) => {
	var confirmation = await user.functions.deleteFromOffice(input);
	return confirmation;
};

// move single document from office collection to staged collection
export const officeToStaged = async (user, input) => {
	var confirmation = await user.functions.officeToStaged(input);
	return confirmation;
};

export const getImgUrl = async (user, product) => {
	var url = await user.functions.getImgUrl(product);
	return url;
};

export const getMetrics = async (user) => {
	var metrics = await user.functions.getMetrics();
	return metrics;
};

export const getSavedMetrics = async (user) => {
	var metrics = await user.functions.getSavedMetrics();
	return metrics;
};

export const saveMetrics = async (user, metrics) => {
	//exlude _id, gppa, pcpa, nppa, margin
	var metricsToSave = metrics.map((metric) => {
		var { _id, gppa, pcpa, nppa, margin, ...rest } = metric;
		return rest;
	});
	console.log("🚀 ~ saveMetrics ~ metricsToSave", metricsToSave);
	var confirmation = await user.functions.saveUserMetrics(metricsToSave);
	return confirmation;
};

// export const watchOfficeCol = async (user, app) => {
// 	const mongodb = app.currentUser.mongoClient("mongodb-atlas");
// 	const officeCol = mongodb.db("ArbitrageTest").collection("officeCollection");

// 	const changeStream = officeCol.watch();

// 	for await (const change of changeStream) {
// 		console.log(change);
// 	}
// };

const MongoService = {
	pushToOffice,
	pushSingleDocOffice,
	stagedToMaster,
	updateMaster,
	getOfficeItems,
	getStagedProducts,
	insertIntoOffice,
	updateOfficeProductInfo,
	deleteFromOffice,
	officeToStaged,
	getImgUrl,
	getMetrics,
	getSavedMetrics,
	saveMetrics,
};

export default MongoService;
