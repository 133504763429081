import React, { useContext } from "react";
import UserContext from "../context/UserContext";
import { TbLogout } from "react-icons/tb";
// import { useNavigate } from "react-router-dom";

function LogOutBtn() {
	const { logOutUser } = useContext(UserContext);
	// const navigate = useNavigate();

	//TODO: get this to Navigate user back to Login page
	// function LogOutUser() {
	// 	if (user.isLoggedIn) {
	// 		console.log(user);
	// 		user.logOut();
	// 		setLoggedIn(false);
	// 		// localStorage.removeItem("mongoUser");
	// 		// console.log("in storage: ", localStorage.getItem("mongoUser"));
	// 		console.log(user);
	// 		navigate("/login");
	// 	} else {
	// 		console.log("no user logged in");
	// 		return;
	// 	}
	// }

	return (
		<button
			className="btn text-light position-absolute end-0 translate-middle-x"
			onClick={logOutUser}>
			<TbLogout className="fs-3" />
		</button>
	);
}

export default LogOutBtn;
