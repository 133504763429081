// metrics will be array of objects we get from mongo

export async function parseMetrics(metrics) {
	// take month and year out of the _id object and add them to the object
	metrics = metrics.map((metric) => {
		if (metric.hasOwnProperty("_id")) {
			return {
				year: metric._id.year,
				month: metric._id.month,
				totalBuyCost: metric.totalBuyCost,
				totalQty: metric.totalQty,
				unitsShipped: metric.unitsShipped,
				unitsSold: metric.unitsSold,
				cogs: metric.cogs,
				revenue: metric.revenue,
				grossProfit: metric.grossProfit,
				expenses: metric.expenses,
				dispersements: metric.dispersements,
			};
		} else {
			return metric;
		}
	});

	// sort each object by ascending month value in the _id object
	var sortedMetrics = await metrics.sort((a, b) => {
		return a.month - b.month;
	});

	var parsedMetrics = await sortedMetrics.map((metric) => {
		return {
			year: metric.year,
			month: metric.month,
			totalBuyCost: parseFloat(metric.totalBuyCost),
			totalQty: metric.totalQty,
			unitsShipped: metric.unitsShipped,
			unitsSold: metric.unitsSold,
			cogs: parseFloat(metric.cogs),
			revenue: parseFloat(metric.revenue),
			grossProfit: parseFloat(metric.grossProfit),
			expenses: parseFloat(metric.expenses),
			dispersements: parseFloat(metric.dispersements),
		};
	});
	return parsedMetrics;
}
