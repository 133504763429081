/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext } from "react";
import { Row, Col, Form, FormGroup, Label, Input } from "reactstrap";
import { BsCheck } from "react-icons/bs";
import { GiCancel } from "react-icons/gi";
import MongoContext from "../../context/MongoContext";

export const OfficeProductEditMode = ({ props, editMode, setEditMode }) => {
	const [productInfo, setProductInfo] = useState({});

	const { updateOfficeProductInfo } = useContext(MongoContext);
	const handleInputChange = (e) => {
		const _id = props._id.toString();
		if (e.target.id === "qty") {
			var qty = parseInt(e.target.value);
			setProductInfo({ ...productInfo, _id: _id, qty: qty });
			return;
		}
		setProductInfo({ ...productInfo, _id: _id, [e.target.id]: e.target.value });
	};

	const handleDateChange = (e) => {
		const _id = props._id.toString();
		e.preventDefault();
		const dateParts = e.target.value.split("-");
		// remove leading 0s from month
		if (dateParts[1].startsWith("0")) {
			dateParts[1] = dateParts[1].substring(1);
		}
		// remove leading 0s from day
		if (dateParts[2].startsWith("0")) {
			dateParts[2] = dateParts[2].substring(1);
		}

		const formattedDate = `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
		setProductInfo({ ...productInfo, _id: _id, date: formattedDate });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		updateOfficeProductInfo(productInfo);
		setEditMode(!editMode);
	};
	return (
		<div
			className="container p-2 border-top border-bottom border-dark text-dark overflow-hidden position-relative animate__animated animate__fadeIn"
			id={props._id}>
			<Form onSubmit={handleSubmit}>
				<Row className="mt-4">
					<Col sm={9}>
						<Row className="mb-3">
							<Col sm={3} className="text-start">
								{/* <h6>{props.date}</h6> */}
								<Input
									id="date"
									className="w-75"
									defaultValue={props.date}
									onChange={handleDateChange}
									type="date"
								/>
							</Col>
							<Col sm={6}></Col>
						</Row>
						<FormGroup>
							<Row className="mb-2 overflow-auto">
								<Col sm={3} className="text-start">
									<Label for="min">Min: $</Label>
									<Input
										id="min"
										className="w-50"
										defaultValue={props.min}
										onChange={handleInputChange}
									/>
								</Col>
								<Col sm={4} className="text-start">
									<Label for="asin">ASIN: </Label>
									<Input
										id="asin"
										defaultValue={props.asin}
										onChange={handleInputChange}
									/>
								</Col>
								<Col sm={5} className="text-start">
									<Label for="qty">Qty: </Label>
									<Input
										id="qty"
										className="w-75"
										defaultValue={props.qty}
										onChange={handleInputChange}
									/>
								</Col>
							</Row>
						</FormGroup>
						<FormGroup>
							<Row>
								<Col sm={3} className="text-start">
									<Label for="max">Max: $</Label>
									<Input
										id="max"
										className="w-50"
										defaultValue={props.max}
										onChange={handleInputChange}
									/>
								</Col>
								<Col sm={4} className="text-start">
									<Label for="attributes">Attr: </Label>
									<Input
										id="attributes"
										defaultValue={props.attributes}
										onChange={handleInputChange}
									/>
								</Col>
								<Col sm={5} className="text-start">
									<Label for="unitCost">Unit Cost: </Label>
									<Input
										id="unitCost"
										className="w-75"
										defaultValue={props.unitCost}
										onChange={handleInputChange}
									/>
								</Col>
							</Row>
						</FormGroup>
					</Col>
					<Col sm={3} className="text-center">
						<img
							className="img-thumbnail text-dark p-0 align-items-center"
							style={{
								objectFit: "contain",
								width: 240 + "px",
								height: 220 + "px",
							}}
							src={props.imgUrl}
							alt="NO IMAGE FOUND"
							loading="lazy"></img>
					</Col>
				</Row>
				<Col sm={9}>
					<FormGroup>
						<Row className="my-3">
							<Col sm={3} className="text-start">
								<Label for="orderNum">Order #</Label>
								<Input
									id="orderNum"
									defaultValue={props.orderNum}
									onChange={handleInputChange}
								/>
							</Col>
							<Col sm={4} className="text-start overflow-auto">
								<Label for="productLink">Product Link:</Label>
								<Input
									id="productLink"
									defaultValue={props.productLink}
									onChange={handleInputChange}
								/>
								{/* <a
								className="link-info"
								target="_blank"
								rel="noopener noreferrer"
								href={props.productLink}>
								{props.productLink === undefined ? "n/a" : <BiLinkExternal />}
							</a> */}
							</Col>
							<Col sm={5} className="text-start">
								<Label for="msku">MSKU: </Label>
								<Input
									id="msku"
									defaultValue={props.msku}
									onChange={handleInputChange}
								/>
							</Col>
							{/* <Col sm={3} className="d-flex position-relative">
						<Label for="unitsReceived" className=" start-0">
							Rcvd:{" "}
						</Label>
						<Input
							id="unitsReceived"
							type="number"
							className="w-25 position-absolute end-50"
							value={props.unitsReceived}
							readOnly={true}
						/>
					</Col> */}
						</Row>
					</FormGroup>
					<FormGroup>
						<Row className="mb-5">
							<Col sm={3} className="text-start">
								<Label for="supplier">Supplier: </Label>
								<Input
									id="supplier"
									defaultValue={props.supplier}
									onChange={handleInputChange}
								/>
							</Col>
							<Col sm={4}></Col>
							<Col sm={5} className="text-start">
								<Label for="upc">UPC: </Label>
								<Input
									id="upc"
									defaultValue={props.upc}
									onChange={handleInputChange}
								/>
							</Col>
							{/* <Col sm={3} className="d-flex position-relative">
						<strong className=" start-0">S/D/R:</strong>
						<input
							id="unitsSDR"
							type="number"
							className="w-25 position-absolute end-50"
							value={props.unitsSDR}
							readOnly={true}
						/>
					</Col> */}
						</Row>
					</FormGroup>
					<FormGroup>
						<Row className="mb-5">
							<Col sm={7} className="text-start">
								<Label for="description">Description: </Label>
								<Input
									id="amzProductTitle"
									defaultValue={props.amzProductTitle}
									onChange={handleInputChange}
								/>
							</Col>
							<Col sm={5} className="text-start">
								<Label for="notes">Notes: </Label>
								<textarea
									id="notes"
									className="align-middle form-control"
									type="text"
									defaultValue={props.notes}
									onChange={handleInputChange}
								/>
							</Col>
						</Row>
					</FormGroup>
				</Col>
				<Row>
					<Col sm={8}></Col>
					<Col sm={4} className="text-end">
						<button
							className="btn btn-sm"
							type="button"
							onClick={() => setEditMode(!editMode)}>
							<GiCancel className="fs-2 text-danger" />
						</button>
						<button className="btn btn-sm" type="submit">
							<BsCheck className="fs-1 text-success" />
						</button>
					</Col>
				</Row>
			</Form>
		</div>
	);
};
