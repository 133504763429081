export async function calcGPPA(grossProfit, customerUnitsPurchased) {
	try {
		var result = (grossProfit / customerUnitsPurchased).toFixed(2);

		if (isNaN(result)) {
			return "N/A";
		} else {
			return Number(result);
		}
	} catch (error) {
		return "N/A";
	}
}
