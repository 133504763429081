import { React } from "react";

const ToggleSwitch = ({ view, setView }) => {
	return (
		<div className="toggle-div">
			<input
				type="checkbox"
				name="onoffswitch"
				className="onoffswitch-checkbox"
				id="myonoffswitch"
				tabIndex="0"
				checked={view}
				onChange={setView}
			/>
			<label className="onoffswitch-label" htmlFor="myonoffswitch">
				<span className="onoffswitch-inner"></span>
				<span className="onoffswitch-switch"></span>
			</label>
		</div>
	);
};

export default ToggleSwitch;
