import { calcGPPA } from "./calcGPPA";
import { calcMargin } from "./calcMargin";
import { calcNPPA } from "./calcNPPA";
import { calcPCPA } from "./calcPCPA";

export async function calcMetrics(data) {
	// console.log("🚀 ~ calcMetrics ~ data:", data);
	// we have to calculate metrics for each month
	// unitsSold = customerUnitsPurchased
	const {
		grossProfit,
		unitsSold,
		expenses,
		revenue,
		cogs,
		dispersements,
		month,
	} = data;
	// some metrics are calculated based on other metrics (GPPA, PCPA)
	var gppa = await calcGPPA(grossProfit, unitsSold);
	// console.log("🚀 ~ calcMetrics ~ gppa:", gppa);
	var pcpa = calcPCPA(expenses, unitsSold);
	var nppa = calcNPPA(gppa, pcpa);
	// console.log("🚀 ~ calcMetrics ~ nppa:", nppa);
	var margin = calcMargin(grossProfit, revenue);

	return {
		month,
		gppa,
		pcpa,
		nppa,
		margin,
	};
}
