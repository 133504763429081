function calculateTotalCost(qty, cost) {
	// remove the dollar sign
	cost = cost.replace("$", "");

	// convert the cost to a float
	const unitCost = parseFloat(cost);
	let totalCost = 0;

	// if a product has no cost, return 0
	if (unitCost === undefined) {
		return totalCost;
	}
	// if a product has no qty, return 0
	else if (qty === undefined) {
		return totalCost;
	} else {
		// calculate the total cost
		totalCost = (unitCost * qty).toFixed(2);
		return totalCost;
	}
}
export default calculateTotalCost;
