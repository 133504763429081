export function calcPCPA(expenses, customerUnitsPurchased) {
	// turn this into a try catch block to avoid errors
	try {
		var result = (expenses / customerUnitsPurchased).toFixed(2);

		if (isNaN(result)) {
			return "N/A";
		} else {
			return Number(result);
		}
	} catch (error) {
		return "ERR";
	}
}
