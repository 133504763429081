/* eslint-disable jsx-a11y/img-redundant-alt */
/**
 * @description a component to display the results from a query
 * to the master sheet database, which may be renamed, since it
 * won't be a sheet anymore.
 */
import { React, memo, useContext, useState } from "react";
import { BiLinkExternal } from "react-icons/bi";
import { ImUndo2 } from "react-icons/im";
import { animateCSS } from "../components/AddAnimation";
import getImgUrl from "../utils/getImgUrl";
import "animate.css";

import MongoContext from "../context/MongoContext";
import UserContext from "../context/UserContext";
/**
 * memo is here for performance, the component "memorizes" the props
 * and will re-render only when they change.
 */
export const StagedProduct = memo(function StagedProduct({
	props,
	undoDelete,
}) {
	// don't need to make a shipping plan within the staged view
	const { addToUpdates, addToShipPlan } = useContext(MongoContext);
	const { user } = useContext(UserContext);

	// keep these to change these values when necessary
	const [productInfo, setProductInfo] = useState({
		unitsReceived: props.unitsReceived,
		unitsSDR: props.unitsSDR,
		notes: props.notes,
		modBy: props.modBy,
	});

	// change handler will call updateProductInfo -> updateProductInfo
	// call addToUpdates.
	const updateProductInfo = (event) => {
		const newData = { ...productInfo, modBy: user.customData.firstName };
		let units;
		let _id = props._id.toString();

		/**
		 * Checking if the units being changed are number values,
		 * if not then we can just insert it as string
		 */
		if (isNaN(parseInt(event.target.value))) {
			newData[event.target.id] = event.target.value;
		} else {
			units = parseInt(event.target.value);
			newData[event.target.id] = units;
		}

		if (event.target.id === "unitsSDR") {
			let toShip = props;
			toShip._id = toShip._id.toString();
			toShip.unitsSDR = units;
			addToShipPlan(_id, toShip);
		}
		setProductInfo(newData);
		addToUpdates(_id, newData);
	};

	const inputReceived = (event) => {
		// regex to check if input is a number
		const reg = /^[0-9\b]+$/;

		// if value isn't blank test regex
		if (event.target.value !== "" || reg.test(event.target.value)) {
			// setReceived(event.target.value);
			// handleReceivedChange(event.target.value);
			updateProductInfo(event);
		} else {
			event.target.value = 0;
			alert("Received must be a value between 0 and 99");
		}
	};
	const sdrChanged = (event) => {
		// regex to check if input is a number
		const reg = /^[0-9\b]+$/;

		// if value isnt blank test regex
		if (
			event.target.value <= productInfo.unitsReceived &&
			reg.test(event.target.value)
		) {
			// handleSdrChange(event.target.value);
			updateProductInfo(event);
		} else {
			event.target.value = 0;
			alert("SDR value must be a value less than or equal to Received");
		}
	};
	const regularStyle =
		"container p-2 rounded border-top border-bottom border-dark text-dark overflow-hidden position-relative";
	const toBeDeleted =
		"container p-2 rounded border-top border-bottom border-dark text-dark bg-danger overflow-hidden position-relative";
	//TODO: may have to implement useEffect hook
	return (
		<div
			className={props.deleteFlag ? toBeDeleted : regularStyle}
			id={props._id}>
			<button
				className="position-absolute text-center bottom-0 end-0 btn btn-sm btn-sky"
				type="button"
				onClick={() =>
					animateCSS(props._id, "fadeOutLeft").then(() => undoDelete(props._id))
				}>
				<ImUndo2 className="fs-4" />
			</button>
			<div className="row mt-4" style={{ maxHeight: 210 + "px" }}>
				<div className="col-9">
					<div className="row mb-3">
						<h6 className="col-3 font-monospace text-start fw-bold my-4">
							{props.date}
						</h6>
						<div className="col-6"></div>
					</div>
					<div
						className="row mb-2 overflow-auto"
						style={{ maxHeight: 70 + "px" }}>
						<div className="col-4 text-start">
							<strong>Min: $</strong>
							{props.min}
						</div>
						<div className="col-4 text-start">
							<strong>ASIN: </strong>
							<a
								className="link-info"
								target="_blank"
								rel="noopener noreferrer"
								href={props.amzLink}>
								{props.asin}
							</a>
						</div>
						<div className="col-4 text-start">
							<strong>Qty: </strong> {props.qty}
						</div>
					</div>
					<div className="row">
						<div className="col-4 text-start">
							<strong>Max: $</strong>
							{props.max}
						</div>
						<div className="col-4"></div>
						<div className="col-4 text-start">
							<strong>Unit Cost: </strong>
							{props.unitCost}
						</div>
					</div>
				</div>
				<div className="col-3 text-start" style={{ height: 210 + "px" }}>
					<img
						className="img-thumbnail text-dark p-0 d-flex align-items-center"
						style={{
							objectFit: "contain",
							width: 240 + "px",
							height: 220 + "px",
						}}
						src={props.imgUrl}
						alt="NO IMAGE FOUND"
						loading="lazy"></img>
				</div>
			</div>
			<div className="row my-3">
				<div className="col-3 text-start">
					<strong>Order #: </strong>
					{props.orderNumber}
				</div>
				<div className="col-3 text-start overflow-auto">
					<strong>Product Link: </strong>
					<a
						className="link-info"
						target="_blank"
						rel="noopener noreferrer"
						href={props.productLink}>
						{props.productLink === undefined ? "n/a" : <BiLinkExternal />}
					</a>
				</div>
				<div className="col-3 text-start">
					<strong>MSKU: </strong>
					{props.msku}
				</div>
				<div className="col-3 d-flex position-relative">
					<strong className="position-absolute start-0">Received: </strong>
					<input
						id="unitsReceived"
						type="number"
						className="w-25 position-absolute end-50"
						value={productInfo.unitsReceived}
						onChange={inputReceived}
					/>
				</div>
			</div>
			<div className="row mb-5">
				<div className="col-3 text-start">
					<strong>Supplier: </strong>
					{props.supplier}
				</div>
				<div className="col-3 text-start">
					<strong>Modified By: </strong>
					{props.modBy}
				</div>
				<div className="col-3 text-start">
					<strong>UPC: </strong>
					{props.upc}
				</div>
				<div className="col-3 d-flex position-relative">
					<strong className="position-absolute start-0">S/D/R :</strong>
					<input
						id="unitsSDR"
						type="number"
						className="w-25 position-absolute end-50"
						value={productInfo.unitsSDR}
						onChange={sdrChanged}
					/>
				</div>
			</div>
			<div className="row mb-5">
				<div className="col-6 text-start">
					<strong>Description: </strong>
					{props.amzProductTitle}
				</div>
				<div className="col-6 text-start">
					<strong>Notes: </strong>
					<input
						id="notes"
						className="align-middle form-control w-75"
						type="text"
						defaultValue={productInfo.notes}
						onBlur={(e) => updateProductInfo(e)}
					/>
				</div>
			</div>
		</div>
	);
});
