function getStoredProducts(updates, products) {
	// search for product with matching _id in products array and change them to match updates
	// return updated products array
	var updatedProducts = products.map((product) => {
		var update = updates.find(
			(update) => update._id === product._id.toString()
		);
		if (update) {
			product.unitsReceived = update.unitsReceived;
			product.unitsSdr = update.unitsSdr;
			return product;
		} else {
			return product;
		}
	});
	return updatedProducts;
}
export default getStoredProducts;
