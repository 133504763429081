import React, { useState } from "react";
import { parseMonth } from "./MetricsUtils/parseMonth";

export default function MetricsRow({ metric, handleChange }) {
	const [userValues, setUserValues] = useState({
		month: metric.month,
		unitsSold: metric.unitsSold,
		cogs: metric.cogs,
		revenue: metric.revenue,
		grossProfit: metric.grossProfit,
		expenses: metric.expenses,
		dispersements: metric.dispersements,
	});

	const handleRowChange = (event) => {
		var newUserValues = userValues;
		// if (isNaN(event.target.value)) {

		// 	return;
		// }
		newUserValues[event.target.id] = Number(event.target.value);

		setUserValues({ ...newUserValues });
		handleChange(newUserValues);
	};

	return (
		<tr>
			<th scope="row" style={{ width: "76px" }}>
				{parseMonth(metric.month)}
			</th>
			{/* <td style={{ width: "76px" }}>{metric.totalQty}</td> */}
			<td style={{ width: "100px" }}>{metric.unitsShipped}</td>
			<td style={{ width: "76px" }}>
				<span>${metric.totalBuyCost}</span>
			</td>
			<td style={{ width: "80px" }}>
				<input
					type="number"
					className="form-control metricInput mx-auto"
					id="unitsSold"
					onChange={handleRowChange}
					value={userValues.unitsSold}
				/>
			</td>
			<td style={{ width: "96px" }}>
				<span className="input-dollar left">
					<input
						type="number"
						className="form-control metricInput d-inline"
						id="cogs"
						onChange={handleRowChange}
						value={userValues.cogs}
					/>
				</span>
			</td>
			<td style={{ width: "96px" }}>
				<span className="input-dollar left">
					<input
						type="number"
						className="form-control metricInput mx-auto d-inline"
						id="revenue"
						onChange={handleRowChange}
						value={userValues.revenue}
					/>
				</span>
			</td>
			<td style={{ width: "96px" }}>
				<span className="input-dollar left">
					<input
						type="number"
						className="form-control metricInput mx-auto d-inline"
						id="grossProfit"
						onChange={handleRowChange}
						value={userValues.grossProfit}
					/>
				</span>
			</td>
			<td style={{ width: "96px" }}>
				<span className="input-dollar left">
					<input
						type="number"
						className="form-control metricInput mx-auto d-inline"
						id="expenses"
						onChange={handleRowChange}
						value={userValues.expenses}
					/>
				</span>
			</td>
			<td style={{ width: "96px" }}>
				<span className="input-dollar left">
					<input
						type="number"
						className="form-control metricInput mx-auto d-inline"
						id="dispersements"
						onChange={handleRowChange}
						value={userValues.dispersements}
					/>
				</span>
			</td>
			<td style={{ width: "8px" }}>
				<span>$</span>
				{metric.gppa}
			</td>
			<td style={{ width: "80px" }}>
				<span>$</span>
				{metric.pcpa}
			</td>
			<td style={{ width: "80px" }}>
				<span>$</span>
				{metric.nppa}
			</td>
			<td style={{ width: "80px" }}>{metric.margin}</td>
		</tr>
	);
}
